import Autocomplete from "@mui/material/Autocomplete";
import MUITextField from "@mui/material/TextField";
import { getTheme, pxToRem, styled } from "~/utils";
import TextField from "../../TextField";
import TextFieldVariable from "~/components/TextFieldVariable";

// Colors
const light = getTheme("colors.light");
const secondary = getTheme("colors.secondary");
const contrast = getTheme("colors.contrast");
const grayBg = getTheme("colors.grayBg");

// Spacings
const minimalSpacing = getTheme("minimalSpacing");
const smallSpacing = getTheme("smallSpacing");

export const SectionFields = styled.div`
  display: flex;
  padding: ${minimalSpacing};
  justify-content: space-between;
`;

export const SectionSelect = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

export const AutocompleteStyled = styled(Autocomplete)`
  width: 100%;

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`;

export const MUITextFieldStyled = styled(MUITextField)`
  background-color: ${contrast};
  border-radius: ${pxToRem(4)};

  & .MuiInputBase-root {
    color: ${light};
    height: ${pxToRem(53)};
  }
`;

export const Field = styled(TextField)`
  width: 42%;
`;

export const FieldVariables = styled(TextFieldVariable)`
  width: 40%;
  margin: 0px 1rem;
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: ${minimalSpacing};
`;

export const Content = styled.div`
  max-width: 100%;
  display: flex;
  max-height: 60vh;
  margin: 0 auto;
  flex-direction: column;
`;

export const SectionParams = styled.div`
  max-width: 100%;
  padding-top: ${pxToRem(20)};
  overflow-y: auto;
  scrollbar-color: ${contrast} ${secondary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.h4`
  font-size: ${pxToRem(16)};
  color: ${light};
  font-weight: 400;
  margin-bottom: ${pxToRem(10)};
`;

export const CardParam = styled.div`
  width: 95%;
  height: ${pxToRem(70)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${pxToRem(5)};
  padding: ${smallSpacing} ${minimalSpacing};
  border: 1px solid ${contrast};
  margin-bottom: ${minimalSpacing};
`;
