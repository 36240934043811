/* eslint-disable no-template-curly-in-string */
import toast from "react-hot-toast";
import { REGEX_VALIDATE_PATH_PARAM, TEST_TYPE } from "../enums";
import { jsonIsValid, toOnlyNumbers, transformData } from "./app.helper";

export const parseHeaders = (data: Record<string, string>): FormFile.Header => data;

export const parseBody = (data: Record<string, string>): Formulation.Body[] => {
  if (!data) return [];

  const keys = Object.keys(data);

  return keys.map((key) => ({
    name: key,
    value: data[key],
    type: typeof data[key],
  }));
};

export const parseQuery = (data: Record<string, string>): Formulation.Query[] => {
  if (!data) return [];

  const keys = Object.keys(data);

  return keys.map((key) => ({
    name: key,
    value: data[key],
    type: typeof data[key],
  }));
};

export const parseRules = (data: Record<string, string>): Formulation.Rules[] => [];

export const parseFileResponse = (data: FormFile.ResponseFromAPI): Formulation.Data[] =>
  data.requests.map((method) => ({
    id: Math.floor(Math.random() * new Date().getTime()),
    name: method.name,
    method: method.method.toUpperCase(),
    URL: method.url.toLowerCase(),
    headers: parseHeaders(method.payload.header),
    body: parseBody(method.payload.body),
    bodyJson: JSON.stringify(transformData(method.payload.body || {}), null, 2),
    query: parseQuery(method.payload.query),
    formData: parseQuery(method.payload.form_data),
    path_param: method.payload.path_param,
    rules: parseRules(method.rules),
    response_params: {
      header_response: {},
    },
  }));

// TO SEND API

const parseValue = (value = "") => {
  if (value === "true") return true;
  if (value === "false") return false;
  if (value === "null") return null;
  if (/^\d+$/.test(value)) return Number(value);

  return replacementVariable(value);
};

export const mapNameAPI = (name: string) => {
  const replaceBarPerUnderscore = name.replace(/\//g, "_");
  const replaceSpacePerUnderscore = replaceBarPerUnderscore.replace(/\s/g, "_");

  return replaceSpacePerUnderscore;
};

const onMapValueToParser = (data: Record<string, any>[]) =>
  data.reduce((acc, curr) => ({ ...acc, [curr.name]: parseValue(curr.value) }), {}) || {};

export const parseValueVariables = (vl: Variables.ConfigParams | string): Variables.ConfigParams | string => {
  if (typeof vl === "string" || !vl) return vl || "";

  return {
    url: vl.url,
    return_key: vl.return_key,
    method: vl.method,
    header: jsonIsValid(vl?.header) ? JSON.parse(vl?.header) : {},
    body: jsonIsValid(vl?.body) ? JSON.parse(vl?.body) : {},
  };
};

export const onReviewRules = (data: Formulation.Rules[]) =>
  data.map((dt) => ({
    ...dt,
    config: replacementVariable(dt.config),
  }));

export const mapJSONInVariables = (data: Variables.ConfigParams) => ({
  ...data,
  body: JSON.parse(data.body),
  header: JSON.parse(data.header),
});

export const parseSetupVariables = (dt: Formulation.SetupVariables[]) =>
  dt.map((d) => ({
    name: d.name,
    type: d.type,
    value: parseValueVariables(d.value),
  }));

export const replacementVariable = (value: string): string =>
  value.includes("$") ? value.replace(/\$([a-zA-Z0-9_]+)/g, "${$1}") : value;

export const onReviewValues = (obj: Record<string, any>): any => {
  const isObject = (value: any): boolean => value && typeof value === "object" && !Array.isArray(value);

  return Object.keys(obj).reduce((acc: any, key) => {
    const value = obj[key];

    if (typeof value === "string") {
      acc[key] = replacementVariable(value);
    } else if (isObject(value)) {
      acc[key] = onReviewValues(value);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const mapTestCaseObject = (data: Record<string, string>) => {
  const keys = Object.keys(data);

  return keys.map((key) => ({
    field: key,
    value: data[key],
  }));
};

export const parseTestCaseAPI = (tst: TestCase.Data): TestCase.API => {
  const typeIndex = TEST_TYPE.findIndex((type) => type === tst.testType) + 1;

  return {
    uid: toOnlyNumbers(tst.uid),
    title: tst.name,
    description: tst.description,
    integration: [],
    assertion: {
      type: typeIndex,
      expected: typeIndex === 1 ? Number(tst.statusCode) : mapTestCaseObject(tst.response),
    },
    values_to_change: tst.values_to_change.map(({ new_value, payload_field, payload_reference }) => ({
      payload_field,
      payload_reference,
      new_value,
    })),
  };
};

export const parseContentToSendAPI = ({ data }: { data: Formulation.Data }): Formulation.DataSendToAPI => ({
  name: mapNameAPI(data.name),
  method: data.method,
  url: replacementVariable(data.URL),
  test_case: data?.test_case?.map(parseTestCaseAPI),
  payload: {
    header: onReviewValues(data.headers),
    body: JSON.parse(data.bodyJson || "{}"),
    query: onMapValueToParser(data.query),
    path_param: onReviewValues(data.path_param),
    form_data: onMapValueToParser(data.formData),
  },
});

export const isValidBodyRequestMethod = (data: Formulation.Data): Boolean => {
  if (!data.name.length) {
    toast.error("Nome do método é obrigatório");
    return false;
  }

  return true;
};

export const parsePathParam = (url = "") => {
  const parsed = url.match(REGEX_VALIDATE_PATH_PARAM) as string[];
  const cleanedValues = parsed?.map((match) => match.replace(/[^a-zA-Z0-9 _-]/g, "")) || [];

  return cleanedValues?.reduce((old: any, current: any) => ({ ...old, [current]: "" }), {}) || {};
};

export const parsedURLToPathParams = (url = "", object: Record<string, string> = {}): Record<string, string> => {
  const parsed = (url.match(REGEX_VALIDATE_PATH_PARAM) as string[]) || [];
  const cleanedValues = parsed?.map((match) => match.replace(/[^a-zA-Z0-9 _-]/g, ""));

  return (
    cleanedValues?.reduce(
      (old: Record<string, string>, current: string) => ({
        ...old,
        [current]: object[current] || "",
      }),
      {}
    ) || {}
  );
};

export const parsedPathParamsToArray = (data: Record<string, string>) => {
  const keys = Object.keys(data) || [];

  return keys.map((key) => ({
    name: key,
    value: data[key],
  }));
};
