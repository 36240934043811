/* eslint-disable react/destructuring-assignment */
import React, { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { Add, Edit } from "~/assets/svg";
import { OPTIONS_HEADERS_MOCK, observer, useStores } from "~/utils";
import ConfirmDelete from "../../ConfirmDelete";
import "react-tabs/style/react-tabs.css";
import {
  Action,
  CardParam,
  Content,
  Field,
  Label,
  SectionFields,
  SectionParams,
  SectionSelect,
  AutocompleteStyled,
  MUITextFieldStyled,
  FieldVariables,
} from "./styles";

type Props = {
  id: number;
  headers: Record<string, string>;
};

const HeadersTabRequest: FC<Props> = ({ id, headers }) => {
  const { formulation, app } = useStores();
  const [name, setName] = useState("");
  const [value, setValue] = useState("");

  const onEditRulesParams = (data: Formulation.ParameterInEditionParams) => {
    formulation.onSetIDMethodSelected(id);
    formulation.onSetParameterInEdition(data);
    app.onModalRules("Header");
  };

  const onSubmit = () => {
    if (!name || !value) {
      toast.error("Preencha todos os campos");
      return;
    }

    const data = {
      value,
      name,
    } as Formulation.Headers;

    const newHeaders = { ...headers, [data.name]: data.value };

    formulation.onChangeMethodsData({
      id,
      headers: newHeaders,
    });

    setName("");
    setValue("");
  };

  const onChangeAutocomplete = (name: string) => {
    setName(name);
    setValue(OPTIONS_HEADERS_MOCK.find((e) => e.label === name)?.value!);
  };

  return (
    <Content>
      <SectionFields>
        <SectionSelect>
          <Label>Nome do Header</Label>

          <AutocompleteStyled
            freeSolo
            disableClearable
            value={name}
            onChangeCapture={(e: any) => onChangeAutocomplete(e.target?.value)}
            onChange={(_, value) => {
              onChangeAutocomplete(value as string);
            }}
            options={OPTIONS_HEADERS_MOCK.map((option) => option.label)}
            renderInput={(params: any) => <MUITextFieldStyled {...params} />}
          />
        </SectionSelect>

        <FieldVariables name="value" label="Valor" initialValue={value} onChange={({ value }) => setValue(value)} />
        <Add onPress={onSubmit} style={{ marginTop: 35, cursor: "pointer" }} />
      </SectionFields>

      <SectionParams>
        {Object.keys(headers)
          .reverse()
          .map((key) => (
            <CardParam key={headers[key]}>
              <SectionSelect>
                <AutocompleteStyled
                  freeSolo
                  onChange={(_, value) => {
                    formulation.onChangeValueHeaders({
                      id,
                      label: value as string,
                      value: headers[key],
                      oldLabel: key,
                    });
                  }}
                  value={key}
                  disableClearable
                  options={OPTIONS_HEADERS_MOCK.map((option) => option.label)}
                  renderInput={(params: any) => <MUITextFieldStyled {...params} />}
                />
              </SectionSelect>

              <Field
                enableVariables
                name="value"
                value={headers[key]}
                onChange={({ target: { name, value } }) => {
                  formulation.onChangeValueHeaders({
                    id,
                    label: key,
                    [name]: value,
                  });
                }}
              />

              <Action>
                <ConfirmDelete key={key} onDelete={() => formulation.onRemoveHeaders({ id, label: key })} />
              </Action>
            </CardParam>
          ))}
      </SectionParams>
    </Content>
  );
};
export default observer(HeadersTabRequest);
